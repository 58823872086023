import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import styles from "./404.module.scss"

class NotFoundPage extends React.Component {
  render() {
 
    return (
      <div className={styles.wrapper}>
        <SEO title="404: Not Found" />
        <div className={styles.container}>
          <h3>A Pure Person</h3>
          <p className={styles.title}>Are you lost? </p>
          <p>Turn around to <a aria-label="Go back home" href="/" alt="Go home">go home. </a> </p>
        </div>
        
      </div>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
